import { StatesModule } from '@uirouter/angular';

import { CanAccessApps } from '../ajs-upgraded-providers';

import { ViewportComponent } from './viewport/viewport.component';
import { StorageHomeComponent } from './components/storage-home/storage-home.component';

export const routes: StatesModule = {
  states: [
    {
      name: 'apps.storage',
      abstract: true,
      component: ViewportComponent
    },
    {
      name: 'apps.storage.home',
      url: '/storage',
      component: StorageHomeComponent,
      resolve: [{
        token: 'canAccessApps',
        deps: [CanAccessApps],
        resolveFn: (canAccessApps) => {
          return canAccessApps();
        }
      }]
    }
  ]
};
